// @flow
import * as Sentry from '@sentry/browser';
import type { ThunkAction, Dispatch } from '../../types';

export const contactFormSubmit = (contact: Object): ThunkAction => async (
  dispatch: Dispatch,
  getState
) => {
  try {
    const body = JSON.stringify({
      contact
    });

    let url = process.env.REACT_APP_CONTACT_API
      ? process.env.REACT_APP_CONTACT_API
      : 'https://ycfrpn1aq9.execute-api.ap-southeast-2.amazonaws.com/uat/contact';

    const response = await fetch(url, {
      method: 'POST',
      body
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const json = await response.json();
    if (json) {
      return true;
    }
    return false;
  } catch (e) {
    Sentry.captureException(e);
    // console.log('contactFormSubmit error', e);
    return false;
  }
};
