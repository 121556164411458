//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import Breadcrumb from '../components/Breadcrumb';
import Message from '../components/Message';
import SelfRegisterForm from '../components/SelfRegisterForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selfRegister } from '../actions/user/selfRegister';
import { getUserData } from 'roy-morgan-auth';

import type { State as AppState, Dispatch } from '../types';

type User = {
  name?: string,
  email?: string,
  phone?: string,
  password?: string
};

type Props = {
  user: User,
  selfRegister: (formData: User) => Promise<any>,
  getUserData: () => Promise<null>
};

type State = {
  message: ?string,
  type: ?string,
  showMessage: boolean
};

class SelfRegister extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      message: '',
      type: '',
      showMessage: false
    };
  }

  handleUpdate = formData => {
    this.props.selfRegister(formData).then(results => {
      if (results.code !== 'API') {
        this.props.getUserData().then(
          success => {
            this.setState({
              showMessage: true,
              message:
                'Thanks for registering. You are now logged in using the credentials you entered below.',
              type: 'success'
            });
          },
          failure => {
            this.setState({
              showMessage: true,
              message: 'Something went wrong. Please try again later.',
              type: 'error'
            });
          }
        );
      }
    });
  };

  closeMessage = () => {
    this.setState({
      showMessage: false
    });
  };

  render() {
    const { message, type, showMessage } = this.state;

    return (
      <Container className="register-page">
        <Grid>
          <Grid.Column width={12} className="ui centered">
            <Breadcrumb topLevelContext="Home" backLink="/" />
          </Grid.Column>
        </Grid>
        <Grid className="ui centered">
          <Grid.Column mobile={12} tablet={6} computer={6}>
            <div className="single-form register" width={6}>
              <Message
                show={showMessage}
                error={type === 'error' ? true : false}
                success={type === 'success' ? true : false}
                content={message || ''}
                onDismiss={this.closeMessage}
              />
              <SelfRegisterForm
                handleFormSubmit={this.handleUpdate}
                user={this.props.user}
                selfRegister={selfRegister}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState, props) => ({
  user: state.auth.user.user
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selfRegister,
      getUserData
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfRegister);
