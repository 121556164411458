// @flow
import * as React from 'react';
import { formatCurrency } from '../helpers';

type Props = {
  country: ?string,
  timePeriod: string,
  price: number
};

class ProductInfoFields extends React.PureComponent<Props> {
  render() {
    const { country, timePeriod, price } = this.props;
    return (
      <div className="product-card__header-info-inner-wrapper">
        <div className="product-card__price">
          <span className="product-card__info-field">
            <strong>Price:</strong>
            &nbsp; {formatCurrency(price, undefined, undefined, 0)}
          </span>
        </div>
        <span className="product-card__info-field">
          <strong>Country:</strong> <br />
          {country ? country : 'N/A'}
        </span>
        <span className="product-card__info-field">
          <strong>Time Period:</strong> <br />
          {timePeriod}
        </span>
      </div>
    );
  }
}

export default ProductInfoFields;
