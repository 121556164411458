//@flow
import React from 'react';
import { Container } from 'semantic-ui-react';

type Props = {
  title?: string,
  backgroundUrl: string
};

class PageBanner extends React.Component<Props> {
  render() {
    const { title, backgroundUrl } = this.props;

    const styles = {
      backgroundImage: `url('${backgroundUrl}')`
    };
    return (
      <div className="page-banner" style={styles}>
        <div className="page-banner__triangle" />
        <div className="page-banner__wrapper">
          <Container>
            <h1 className="page-banner__title">{title}</h1>
          </Container>
        </div>
      </div>
    );
  }
}

export default PageBanner;
