// @flow
import * as actions from '../actions/user/orders';
// import type { Action, State } from '../types';

const intialState = {
  isFetching: false
};

export const orders = (state: Object = intialState, action: Object) => {
  switch (action.type) {
    case actions.GET_ORDERS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.GET_ORDERS_SUCCESS:
      return {
        ...state,
        // $FlowFixMe
        orders: [...action.payload],
        isFetching: false
      };
    case actions.GET_ORDERS_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};
