// @flow
import * as React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import LoadSVG from './LoadSVG';
import ProductInfoFields from './ProductInfoFields';
import type { Product } from '../types';
import { Link } from 'react-router-dom';

type Props = {
  product: Product,
  itemExistsInCart: boolean,
  addToCart: (product: Product) => any
};

class ProductCard extends React.Component<Props> {
  handleAddToCart = function(event: Object, product: Product) {
    let cartLeftOffset = document
      .getElementsByClassName('online-store__cart-header')[0]
      .getBoundingClientRect().left;

    let flyingImg = document.createElement('img');
    flyingImg.className = 'flying-image';
    flyingImg.src = product.iconLocation;
    flyingImg.style.position = 'fixed';
    flyingImg.style.transition = 'all 0.5s ease';
    flyingImg.style.zIndex = '10000';
    flyingImg.style.width = '40px';
    flyingImg.style.top =
      event.target.closest('.product-card').offsetTop -
      window.pageYOffset +
      90 +
      'px';
    flyingImg.style.left =
      event.target.closest('.product-card').getBoundingClientRect().left +
      40 +
      'px';

    let appendedFlyingImage = event.target
      .closest('body')
      .appendChild(flyingImg);
    setTimeout(function() {
      flyingImg.style.top = '0px';
      flyingImg.style.left = cartLeftOffset + 'px';
      flyingImg.style.width = '40px';
      flyingImg.style.opacity = '0';
    }, 0);
    setTimeout(function() {
      setTimeout(function() {
        document
          .getElementsByClassName('online-store__cart-header-icon')[0]
          .classList.add('shake');
      }, 0);
      setTimeout(function() {
        document
          .getElementsByClassName('online-store__cart-header-icon')[0]
          .classList.remove('shake');
      }, 500);
      appendedFlyingImage.parentNode.removeChild(appendedFlyingImage);
    }, 500);
    this.props.addToCart(product);
  };

  render() {
    const { product, itemExistsInCart } = this.props;
    return (
      <div className="product-card">
        <div className="product-card__wrapper">
          <div className="product-card__header">
            <span className="product-card__svg">
              <LoadSVG colour="#00a3c7" src={product.iconLocation} />
            </span>
            <div className="product-card__header-info">
              <h3 className="product-card__header-title">{product.name}</h3>
              <ProductInfoFields
                country={product.countryName}
                timePeriod={product.timePeriod}
                price={product.price}
              />
            </div>
          </div>
          <div className="product-card__controls">
            <div className="product-card__control-col">
              <Button
                className="product-card__button"
                as={Link}
                to={`/product/${product.slug}`}
                data-testid="view-details"
              >
                View Details
              </Button>
            </div>
            <div className="product-card__control-col">
              {!itemExistsInCart ? (
                <Button
                  data-testid="add-to-cart"
                  onClick={event => {
                    this.handleAddToCart(event, product);
                  }}
                  className="product-card__button product-card__button--add-to-cart am_add_to_cart"
                >
                  <i className="plus circle icon" />
                  Add To Cart
                </Button>
              ) : (
                <span data-testid="added-to-cart" className="already-in-cart">
                  <Icon name="check" />
                  Already In Cart
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCard;
