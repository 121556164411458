// @flow
import * as actions from '../actions/pages/cmsProductsOptions';
// import type { Action } from '../types';

const intialState = {
  isFetching: false
};

export const cmsProductsOptions = (
  state: Object = intialState,
  action: Object
) => {
  switch (action.type) {
    case actions.GET_PRODUCTS_OPTIONS_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.GET_PRODUCTS_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    case actions.GET_PRODUCTS_OPTIONS_SUCCESS:
      return {
        ...state,
        // $FlowFixMe
        ...action.payload,
        isFetching: false
      };
    default:
      return state;
  }
};
