// @flow
import * as React from 'react';
import type { Dispatch } from '../types';
import { allProductSearch } from '../actions/pages/products';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingIndicator from '../components/LoadingIndicator';

type Props = {
  isFetching: boolean,
  allProducts: Array<Object>,
  allProductSearch: () => Promise<*>
};

class ProductMap extends React.Component<Props> {
  componentDidMount() {
    this.props.allProductSearch();
  }

  render() {
    const { isFetching, allProducts } = this.props;
    if (isFetching || !allProducts) {
      return <LoadingIndicator />;
    }
    return (
      <ul>
        {allProducts.map((product, i) => {
          return (
            <li key={i}>
              <a href={`/product/${product.slug}`}>{product.name}</a>
            </li>
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFetching: state.allProductList.isFetching,
  allProducts: state.allProductList.products
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      allProductSearch
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductMap);
