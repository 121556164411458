//@flow
import React from 'react';
import { Label } from 'semantic-ui-react';

type Props = {
  title: string
};

class SearchResultItem extends React.Component<Props> {
  render() {
    const { title } = this.props;
    return <Label content={title} />;
  }
}

export default SearchResultItem;
