// @flow
import React from 'react';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PageBanner from '../components/PageBanner';
import { bindActionCreators } from 'redux';
import { loadPage } from '../actions/pages/readPage';
import LoadingIndicator from '../components/LoadingIndicator';
import ReactHTMLParser from 'react-html-parser';
import type { Dispatch, Page } from '../types';

type Props = {
  page: ?Page,
  loadPage: (slug: string) => Promise<*>,
  pageSlug: string,
  isFetching: boolean
};

export class GenericPage extends React.Component<Props> {
  componentDidMount() {
    this.props.loadPage(this.props.pageSlug);
  }

  render() {
    const { page, isFetching, pageSlug } = this.props;

    if (isFetching && !page) {
      return <LoadingIndicator />;
    }

    return (
      <div>
        {page && page.title && page.content && (
          <>
            <PageBanner
              title={page && page.title.rendered}
              backgroundUrl="https://s3-ap-southeast-2.amazonaws.com/rmr-uat-live-cms-uploads/wp-content/uploads/2018/11/18170333/checkout-banner.jpg"
            />
            <Container className={`${pageSlug}-page static-content-here`}>
              <div className="info">
                {ReactHTMLParser(page && page.content.rendered)}
              </div>
            </Container>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  page: state.pages.pages[props.pageSlug],
  isFetching: state.pages.isFetching
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadPage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericPage);
