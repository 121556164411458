// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTreeNav, productSearch } from '../actions/pages/products';
import { setProductsOptions } from '../actions/pages/cmsProductsOptions';
import { addToCart } from '../actions/cart/addToCart';
import LoadingIndicator from '../components/LoadingIndicator';
import ProductSearch from './ProductSearch';
import ProductCard from '../components/ProductCard';
import RoyMorganContact from '../components/RoyMorganContact';
import { Card, Pagination, Grid, Container } from 'semantic-ui-react';
import TreeNav from '../components/TreeNav';
import type { Dispatch } from '../types';
import URLSearchParams from 'url-search-params';

type Props = {
  page: any,
  treenav: any,
  isFetching: boolean,
  match: Object,
  history: Object,
  location: Object,
  promoBanner: string,
  totalPages: number,
  totalItems: number,
  currentPage: number,
  cartItems: Object,
  searchedCategory: string,
  searchedKeyword: string,
  productSearch: Function,
  getTreeNav: () => Promise<*>,
  addToCart: () => Promise<*>,
  setProductsOptions: () => Promise<*>,
  setReportType: Function
};

type State = {
  reportType: number
};

class Products extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const urlParams = new URLSearchParams(this.props.location.search);
    const reportTypeIdQuery = urlParams.get('reportTypeId');

    this.state = {
      reportType: reportTypeIdQuery
    };
  }

  componentDidMount() {
    this.props.getTreeNav();
    this.props.setProductsOptions();
  }

  handlePaginationChange = (e, { activePage }) => {
    const { searchedCategory, searchedKeyword } = this.props;
    const { reportType } = this.state;
    this.props.productSearch(
      searchedCategory,
      searchedKeyword,
      reportType,
      activePage
    );
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  newProductSearch = (searchedCategory, searchedKeyword, reportTypeId) => {
    this.props.productSearch(
      searchedCategory,
      searchedKeyword,
      reportTypeId,
      1
    );
  };

  render() {
    const {
      isFetching,
      page,
      treenav,
      match,
      history,
      location,
      promoBanner,
      totalPages,
      totalItems,
      cartItems,
      currentPage
    } = this.props;

    const { reportType } = this.state;
    var itemExistsInCart = false;
    return (
      <Container className="products-listing">
        <Grid columns={12} stackable={true}>
          <Grid.Row>
            <Grid.Column width={3}>
              {treenav && treenav.treenav && (
                <TreeNav
                  treeNav={treenav.treenav}
                  treeflat={treenav.treeflat}
                  match={match}
                  location={location}
                  reportTypeId={reportType}
                  productSearch={this.newProductSearch}
                />
              )}
            </Grid.Column>
            <Grid.Column width={9}>
              <ProductSearch
                match={match}
                history={history}
                location={location}
                totalProducts={totalItems}
                page={currentPage}
                reportTypeId={reportType}
              />
              {isFetching && <LoadingIndicator />}

              {!isFetching && totalItems === 0 && (
                <div className="no-results-content">
                  <p>
                    Sorry, no results found. We may have the data needed for the
                    profile or report that you are looking for.
                  </p>
                  <p>
                    Contact us at Roy Morgan Online Store on{' '}
                    <RoyMorganContact.PhoneLink /> or email any queries to{' '}
                    <RoyMorganContact.EmailLink />
                  </p>
                </div>
              )}

              {!isFetching && totalPages > 0 && (
                <div className="product-listing__pagination-wrapper product-listing__pagination-wrapper--top">
                  <Pagination
                    activePage={currentPage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={totalPages}
                    ellipsisItem={null}
                  />
                </div>
              )}
              {!isFetching && (
                <Card.Group itemsPerRow={2} className="search-results">
                  {page &&
                    page.products &&
                    page.products.map((product, i) => {
                      itemExistsInCart =
                        cartItems &&
                        product &&
                        cartItems.some(function(el) {
                          return el.id === product.id;
                        });
                      return (
                        <ProductCard
                          key={i}
                          product={product}
                          itemExistsInCart={itemExistsInCart}
                          addToCart={this.props.addToCart}
                        />
                      );
                    })}
                </Card.Group>
              )}
              {!isFetching && totalPages > 0 && (
                <div className="product-listing__pagination-wrapper">
                  <Pagination
                    activePage={currentPage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
          {promoBanner && (
            <img
              className="ui fluid image"
              alt="Promo Banner"
              src={promoBanner}
            />
          )}
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    isFetching: state.productList.isFetching,
    page: state.productList,
    totalPages: state.productList.totalPages,
    currentPage: state.productList.currentPage,
    totalItems: state.productList.totalItems,
    searchedCategory: state.productList.searchedCategory,
    searchedKeyword: state.productList.searchedKeyword,
    treenav: state.treenav,
    promoBanner:
      state.cmsProductsOptions &&
      state.cmsProductsOptions.productListingPromoBanner &&
      state.cmsProductsOptions.productListingPromoBanner.url,
    cartItems: state && state.cart && state.cart.cartItems
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getTreeNav,
      addToCart,
      productSearch,
      setProductsOptions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Products);
