//@flow
import * as actions from '../actions/pages/homepageSearch';
// import type { Action } from '../types';

const intialState = {
  isFetching: false,
  searchResults: []
};

export const homepageSearch = (state: Object = intialState, action: Object) => {
  switch (action.type) {
    case actions.HOMEPAGE_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.HOMEPAGE_SEARCH_SUCCESS:
      return {
        ...state,
        // $FlowFixMe
        searchResults: action.payload.map((item, i) => {
          return { title: item };
        }),
        isFetching: false
      };
    case actions.HOMEPAGE_SEARCH_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};
