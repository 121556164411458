// @flow
import type { MessagesState } from '../types';
import { PROCESS_ORDER_FAILURE } from '../actions/cart/checkout';
import { LOG_OUT_SUCCESS } from 'roy-morgan-auth';

const initialState: MessagesState = {
  content: undefined,
  type: undefined
};

/* Reducer */
export const messages = (state: MessagesState, action: Object) => {
  switch (action.type) {
    case PROCESS_ORDER_FAILURE:
      return {
        content: `There was an error with the checkout: ${action.error}`,
        type: 'error'
      };

    case 'USER_UPDATE_FAILURE':
    case 'USER_UPDATE_PASSWORD_FAILURE':
    case 'SELF_REGISTER_FAILURE':
      return {
        content: action.error,
        type: 'error'
      };

    case 'USER_UPDATE_SUCCESS':
      return {
        content: 'User details successfully updated',
        type: 'success'
      };

    case 'USER_UPDATE_PASSWORD_SUCCESS':
      return {
        content: 'User password updated successfully'
      };

    case LOG_OUT_SUCCESS:
      return {
        content: 'Logged out',
        type: 'warning'
      };

    case 'MESSAGES_RESET':
      return initialState;

    case 'SELF_REGISTER_SUCCESS':
      return {
        content:
          'Thanks for registering. You are now logged in using the credentials you entered below.',
        type: 'success'
      };

    default:
      return initialState;
  }
};
