// @flow
import * as actions from '../actions/pages/products';
// import type { Action, State } from '../types';
import { transformTree, normalizeTree } from '../helpers/transformers';

const intialState = {
  isFetching: false
};

export const productList = (state: Object = intialState, action: Object) => {
  switch (action.type) {
    case actions.SET_PRODUCT_LIST_REQUEST:
    case actions.PRODUCT_SEARCH_REQUEST:
    case actions.PRODUCT_RELATED_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.SET_PRODUCT_LIST_SUCCESS:
    case actions.PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        // $FlowFixMe
        currentPage: action.payload.currentPage,
        // $FlowFixMe
        products: [...action.payload.items],
        // $FlowFixMe
        totalPages: action.payload.totalPages,
        // $FlowFixMe
        totalItems: action.payload.totalItems,
        searchedCategory: action.searchedCategory,
        searchedKeyword: action.searchedKeyword,
        isFetching: false
      };
    case actions.PRODUCT_RELATED_SEARCH_SUCCESS:
      return {
        ...state,
        products: [...action.payload],
        isFetching: false
      };
    case actions.SET_PRODUCT_LIST_FAILURE:
    case actions.PRODUCT_SEARCH_FAILURE:
    case actions.PRODUCT_RELATED_SEARCH_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};

export const allProductList = (state: Object = intialState, action: Object) => {
  switch (action.type) {
    case actions.ALL_PRODUCT_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.ALL_PRODUCT_SEARCH_SUCCESS:
      return {
        ...state,
        products: [...action.payload.items],
        isFetching: false
      };
    case actions.ALL_PRODUCT_SEARCH_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};

export const treenav = (state: Object = intialState, action: Object) => {
  switch (action.type) {
    case actions.GET_TREE_NAV_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.GET_TREE_NAV_SUCCESS:
      return {
        ...state,
        treenav: transformTree(action.payload), // todo: remove and use flat tree only
        treeflat: normalizeTree(transformTree(action.payload)),
        isFetching: false
      };
    case actions.GET_TREE_NAV_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};

export const productPage = (state: Object = intialState, action: Object) => {
  switch (action.type) {
    case actions.GET_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case actions.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: { ...action.payload },
        isFetching: false
      };
    case actions.GET_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false
      };
    default:
      return state;
  }
};
