//@flow
import React from 'react';
import { Grid, Container, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SideMenu from '../components/SideMenu';
import Settings from '../containers/Settings';
import MyOrders from '../components/MyOrders';
import { getOrder } from '../actions/user/order';
import { getOrders } from '../actions/user/orders';

import type { Order } from '../types';
import type { Dispatch } from '../types';

type Props = {
  match: Object,
  getOrder: (id: number) => Promise<any>,
  getOrders: () => Promise<*>,
  order: Order,
  orders: Array<Order>
};

class MyOrder extends React.Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { orderId } = this.props.match.params;
    this.props.getOrder(orderId);
    this.props.getOrders();
  }

  render() {
    const currentUrl = this.props.match.url;
    const { order, orders } = this.props;

    const items = [
      {
        label: 'My Details',
        path: `/my-account/details`,
        container: () => <Settings />,
        icon: 'product'
      },
      {
        label: 'My Orders',
        path: `/my-account/orders`,
        container: () => <MyOrders orders={orders} />,
        icon: 'details'
      }
    ];
    var orderDate = order && order.orderDate && new Date(order.orderDate);
    var currency =
      order && order.country && order.country === 'Australia' ? '$' : 'A$';
    return (
      <Container className="my-account-page">
        <Grid className="side-menu-layout">
          <Grid.Column
            mobile={12}
            tablet={3}
            computer={3}
            className="side-menu-column"
          >
            <SideMenu currentPath={currentUrl} items={items} />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={9} computer={9}>
            <Grid>
              <Grid.Row className="my-account-page__order-details">
                <Grid.Column mobile={12} tablet={12} computer={6}>
                  <h2>Order #{order && order.id} Details</h2>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Order Id:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {order && order.id}
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Order Date:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {orderDate &&
                          new Intl.DateTimeFormat('en-AU', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                          }).format(orderDate) +
                            ' ' +
                            new Intl.DateTimeFormat('en-AU', {
                              hour: '2-digit',
                              minute: '2-digit',
                              second: '2-digit',
                              hour12: true
                            }).format(orderDate)}
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Payment Status:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {order && order.paymentStatus}
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Payment Method:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {order && order.paymentMethod}
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Order Status:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {order && order.orderStatus}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  mobile={12}
                  tablet={12}
                  computer={6}
                  className="my-account-page__account_information"
                >
                  <h2>Account Information</h2>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Customer Name:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {order && order.buyerName}
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Email:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {order && order.email}
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        Phone:
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={6} computer={6}>
                        {order && order.phone}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column mobile={12} tablet={6} computer={6}>
                  <h2>Billing Address</h2>
                  {order && order.billingAddress}
                  {order && order.country && ', ' + order.country}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="order__item-listing">
                <Grid.Column width={12}>
                  <h2>Items Ordered</h2>
                  <Grid>
                    <Grid.Row className="order__item-listing-header">
                      <Grid.Column computer={1} tablet={1} mobile={12}>
                        SKU
                      </Grid.Column>
                      <Grid.Column
                        className="text-left item-name"
                        computer={4}
                        tablet={4}
                        mobile={12}
                      >
                        Item Name
                      </Grid.Column>
                      <Grid.Column computer={1} tablet={1} mobile={12}>
                        Period
                      </Grid.Column>
                      <Grid.Column computer={2} tablet={2} mobile={12}>
                        Status
                      </Grid.Column>
                      <Grid.Column computer={2} tablet={2} mobile={12}>
                        Delivery Date
                      </Grid.Column>
                      <Grid.Column computer={2} tablet={2} mobile={12}>
                        Download
                      </Grid.Column>
                    </Grid.Row>
                    {order &&
                      order.items &&
                      order.items.map((item, rowIndex) => {
                        const time = item.timePeriod.split(' ');
                        const month = formatMonth(time[0]);
                        const year = time[1];

                        return (
                          <Grid.Row
                            key={rowIndex}
                            className={rowIndex % 2 === 0 ? 'grey' : ''}
                          >
                            <Grid.Column computer={1} tablet={1} mobile={12}>
                              {item.skuNumber}
                            </Grid.Column>
                            <Grid.Column
                              className="text-left item-name"
                              computer={4}
                              tablet={4}
                              mobile={12}
                            >
                              {item.name}
                            </Grid.Column>
                            <Grid.Column computer={1} tablet={1} mobile={12}>
                              {month} {year}
                            </Grid.Column>
                            <Grid.Column computer={2} tablet={2} mobile={12}>
                              {item.orderStatus}
                            </Grid.Column>
                            <Grid.Column computer={2} tablet={2} mobile={12}>
                              {item.deliveryDate &&
                                new Intl.DateTimeFormat('en-AU', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit'
                                }).format(new Date(item.deliveryDate))}
                            </Grid.Column>
                            <Grid.Column
                              computer={2}
                              tablet={2}
                              mobile={12}
                              className="order__download-url"
                            >
                              {order.paymentStatus.indexOf('failed') < 0 ? (
                                item.downloadUrl ? (
                                  <a
                                    href={
                                      item.downloadUrl ? item.downloadUrl : '#'
                                    }
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <Icon name="download" />
                                    Download
                                  </a>
                                ) : (
                                  <div>
                                    <Icon name="shipping fast" />
                                    In Transit
                                  </div>
                                )
                              ) : (
                                <span>
                                  <Icon name="left ban icon" />
                                </span>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        );
                      })}
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="order__totals">
                <Grid.Column computer={6} tablet={6} mobile={12} />
                {order && (
                  <Grid.Column computer={6} tablet={6} mobile={12}>
                    <h2>Totals:</h2>
                    <Grid>
                      {order.subtotal > 0 && (
                        <Grid.Row>
                          <Grid.Column width={6}>Subtotal:</Grid.Column>
                          <Grid.Column width={5}>
                            {currency + '' + order.subtotal.toFixed(2)}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {order.gst > 0 && (
                        <Grid.Row>
                          <Grid.Column width={6}>GST:</Grid.Column>
                          <Grid.Column width={5}>
                            {currency + '' + order.gst.toFixed(2)}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {order.couponValid && order.couponCode && (
                        <Grid.Row>
                          <Grid.Column width={6}>Coupon:</Grid.Column>
                          <Grid.Column width={5}>
                            {"'" + order.couponCode + "'"}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      {order.couponValid && order.discount && (
                        <Grid.Row>
                          <Grid.Column width={6}>Discount:</Grid.Column>
                          <Grid.Column width={5}>
                            {currency + '' + order.discount.toFixed(2)}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      <Grid.Row>
                        <Grid.Column width={6}>Total:</Grid.Column>
                        <Grid.Column width={5}>
                          {currency + '' + order.total.toFixed(2)}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    user: state.auth.user.user,
    isFetching: state.order && state.order.isFetching,
    order: state.order && state.order.order,
    orders: state.orders.orders
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // setProductList,
      getOrder,
      getOrders
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyOrder);

function formatMonth(month) {
  switch (month) {
    case 'January':
      return 'Jan';
    case 'February':
      return 'Feb';
    case 'March':
      return 'Mar';
    case 'April':
      return 'Apr';
    case 'May':
      return 'May';
    case 'June':
      return 'Jun';
    case 'July':
      return 'Jul';
    case 'August':
      return 'Aug';
    case 'September':
      return 'Sept';
    case 'October':
      return 'Oct';
    case 'November':
      return 'Nov';
    case 'December':
      return 'Dec';
    default:
      return month;
  }
}
