//@flow
import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import SideMenu from '../components/SideMenu';
import Settings from '../containers/Settings';
import MyOrders from '../components/MyOrders';
import { getOrders } from '../actions/user/orders';
import type { Dispatch } from '../types';

type Props = {
  match: Object,
  orders: any,
  getOrders: () => Promise<*>
};

class MyAccount extends React.Component<Props> {
  componentDidMount() {
    this.props.getOrders();
  }

  render() {
    const currentUrl = this.props.match.url;
    const {
      //  isFetching,
      orders
    } = this.props;
    const items = [
      {
        label: 'My Details',
        path: `/my-account/details`,
        container: () => <Settings />,
        icon: 'product'
      },
      {
        label: 'My Orders',
        path: `/my-account/orders`,
        container: () => <MyOrders orders={orders} />,
        icon: 'details'
      }
    ];

    return (
      <Container className="my-account-page">
        <Grid className="side-menu-layout">
          <Grid.Column
            mobile={12}
            tablet={3}
            computer={3}
            className="side-menu-column"
          >
            <SideMenu currentPath={currentUrl} items={items} />
          </Grid.Column>
          <Grid.Column mobile={12} tablet={9} computer={9}>
            <Grid>
              <Grid.Column>
                {items.map((item, index) => {
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      render={item.container}
                    />
                  );
                })}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    user: state.auth.user.user,
    orders: state.orders.orders
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // setProductList,
      getOrders
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount);
