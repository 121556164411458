// @flow
import * as React from 'react';
import { Message as SemanticMessage } from 'semantic-ui-react';
import CSSTransition from 'react-transition-group/CSSTransition';

type Props = {
  error?: boolean,
  warning?: boolean,
  success?: boolean,
  content?: string,
  children?: any,
  show?: boolean,
  onDismiss?: () => void
};

class Message extends React.Component<Props> {
  render() {
    const duration = 500;
    const {
      show = true,
      error,
      warning,
      success,
      content,
      onDismiss
    } = this.props;

    return (
      <CSSTransition
        in={show}
        appear={true}
        classNames="message-transition"
        timeout={duration}
      >
        {show ? (
          <SemanticMessage
            error={error}
            warning={warning}
            success={success}
            content={content}
            onDismiss={onDismiss}
          >
            {this.props.children}
          </SemanticMessage>
        ) : (
          <div />
        )}
      </CSSTransition>
    );
  }
}

export default Message;
