// @flow
import React from 'react';
import { Button, Grid, Form, GridColumn, Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeFromCart } from '../actions/cart/removeFromCart';
import { applyCoupon } from '../actions/cart/applyCoupon';
import { formatCurrency } from '../helpers';
import type { Dispatch } from '../types';

type Props = {
  isLoading: boolean,
  showTotal?: boolean,
  showHeading?: boolean,
  selectedCountry?: string,
  couponValid?: boolean,
  couponCode?: string,
  cartItems: Array<Object>,
  handleFormSubmit: () => void,
  applyCoupon: Promise<null>,
  removeFromCart: () => Promise<*>
};

type State = {
  couponMessageStatus: boolean,
  couponMsg: string,
  couponMsgClass: string,
  couponCodeInput: string
};

class ShoppingCart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // $FlowFixMe
    this.handleCouponInputChange = this.handleCouponInputChange.bind(this);
    // $FlowFixMe
    this.handleApplyCoupon = this.handleApplyCoupon.bind(this);
    this.state = {
      couponMessageStatus: false,
      isLoading: false,
      couponMsg: '',
      couponMsgClass: 'invalid',
      couponCodeInput: ''
    };
  }

  componentDidMount() {
    const { cartItems, applyCoupon, couponCode } = this.props;
    if (cartItems.length) {
      const payload = {
        cart: cartItems,
        couponCode: couponCode
      };
      // $FlowFixMe
      applyCoupon(payload);
    }
  }

  handleCouponInputChange(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      ...this.state,
      couponCodeInput: value
    });
  }

  removeFromCart = (event, product) => {
    // $FlowFixMe
    this.props.removeFromCart(product);
    this.setState({ ...this.state });
  };

  handleApplyCoupon(ev) {
    ev.preventDefault();
    const { cartItems, applyCoupon } = this.props;
    const { couponCodeInput } = this.state;

    var cartObj = cartItems.map(function(cartItem) {
      return {
        id: cartItem.id,
        price: cartItem.price,
        name: cartItem.name,
        slug: cartItem.slug
      };
    });

    if (cartObj.length) {
      const payload = {
        cart: cartObj,
        couponCode: couponCodeInput
      };
      // $FlowFixMe
      applyCoupon(payload).then(results => {
        if (results.couponValid) {
          this.setState({
            couponMessageStatus: true,
            couponMsg: 'Coupon Applied Successfully!',
            couponMsgClass: 'valid',
            couponCodeInput: ''
          });
        } else {
          this.setState({
            couponMessageStatus: true,
            couponMsg: 'Looks like your coupon code is not valid!',
            couponMsgClass: 'invalid'
          });
        }
      });
    } else {
      this.setState({
        couponMessageStatus: true,
        couponMsg: 'Looks like your cart is empty!',
        couponMsgClass: 'invalid'
      });
    }
  }

  render() {
    const {
      isLoading,
      cartItems,
      showTotal,
      showHeading,
      couponCode,
      couponValid,
      selectedCountry
    } = this.props;

    const {
      couponMessageStatus,
      couponMsg,
      couponMsgClass,
      couponCodeInput
    } = this.state;

    let subTotal = 0;
    let discountedAmount = 0;
    let total = 0;
    let currency = selectedCountry === 'Australia' ? '' : 'A';
    // TODO: need to clarify below calculations
    cartItems.forEach(function(item) {
      subTotal += item.discountAmount ? item.discountAmount : item.price;
      discountedAmount +=
        item.discountedAmount &&
        (item.discountAmount || item.discountedAmount === item.price)
          ? item.discountedAmount
          : 0;
      total += item.price - (item.discountedAmount ? item.discountedAmount : 0);
    });
    return (
      <div className="shopping-cart">
        <div className="shopping-cart__cart-items">
          {showHeading && <h2>Review your order</h2>}
          <div className="ui aligned padded grid">
            {cartItems &&
              cartItems.map((cartItem, i) => {
                return (
                  <div className="shopping-cart__item-row" key={i}>
                    <div className="shopping-cart__product-name-wrapper">
                      <Link to={cartItem && '/product/' + cartItem.slug}>
                        {cartItem && cartItem.name}
                      </Link>
                    </div>
                    <div className="shopping-cart__price-col">
                      <span className="shopping-cart__price-col-wrapper">
                        {couponValid &&
                        cartItem &&
                        (cartItem.discountAmount ||
                          cartItem.discountedAmount === cartItem.price) ? (
                          <span>
                            <strike>
                              {cartItem && cartItem.price
                                ? currency + '' + formatCurrency(cartItem.price)
                                : ''}
                            </strike>
                            <div className="shopping-cart__discount-amount">
                              {cartItem &&
                              (cartItem.discountAmount ||
                                cartItem.discountedAmount === cartItem.price)
                                ? currency +
                                  '' +
                                  formatCurrency(cartItem.discountAmount)
                                : ''}
                            </div>
                          </span>
                        ) : cartItem && cartItem.price ? (
                          currency + '' + formatCurrency(cartItem.price)
                        ) : (
                          ''
                        )}
                      </span>
                      <Button
                        className="shopping-cart__remove-item"
                        onClick={event => this.removeFromCart(event, cartItem)}
                      >
                        <Icon name="minus circle" />
                      </Button>
                    </div>
                  </div>
                );
              })}
            {cartItems && cartItems.length === 0 && (
              <span className="noPaddingOrMargin">Cart is empty</span>
            )}
          </div>
        </div>
        <div className="apply-coupon-form">
          <Grid className="apply-coupon-container">
            <h2>Coupon</h2>
            <Grid.Row>
              <GridColumn mobile={12} tablet={12} computer={8}>
                <Form.Input
                  placeholder="Coupon Code"
                  name="couponCode"
                  type="text"
                  required
                  value={couponCodeInput}
                  onChange={this.handleCouponInputChange}
                />
              </GridColumn>
              <GridColumn mobile={12} tablet={12} computer={4}>
                <Button
                  className="coupon-code-form__submit am_checkout_coupon {(isLoading) ? 'loading' : ''}"
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={this.handleApplyCoupon}
                >
                  Apply
                </Button>
              </GridColumn>
            </Grid.Row>
            {couponMessageStatus && (
              <span className={couponMsgClass + ' noPaddingOrMargin'}>
                {couponMsg}
              </span>
            )}
          </Grid>
        </div>
        <Grid className="checkout-total-price">
          <h2>Totals:</h2>
          <Grid.Row>
            <Grid.Column width={12}>
              {showTotal && (
                <div>
                  Subtotal:
                  <span className="amount bold">
                    {selectedCountry === 'Australia'
                      ? currency + '' + formatCurrency(subTotal - subTotal / 11)
                      : currency + '' + formatCurrency(subTotal)}
                  </span>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
          {selectedCountry === 'Australia' && showTotal && (
            <Grid.Row>
              <Grid.Column width={12}>
                <div>
                  GST:{' '}
                  <span className="bold amount">
                    {currency + '' + formatCurrency(subTotal / 11)}
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}

          {discountedAmount > 0 && couponValid && couponCode && (
            <Grid.Row>
              <Grid.Column width={12}>
                <div>
                  Coupon:{' '}
                  <span className="bold amount">{"'" + couponCode + "'"}</span>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
          {discountedAmount > 0 && couponValid && couponCode && (
            <Grid.Row>
              <Grid.Column width={12}>
                <div>
                  Discount:{' '}
                  <span className="bold amount">
                    - {currency}
                    {formatCurrency(discountedAmount)}
                  </span>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={12}>
              {showTotal && (
                <div>
                  Total:{' '}
                  <span className="bold amount">
                    {currency}
                    {formatCurrency(total)}
                  </span>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    cartItems: state.cart && state.cart.cartItems,
    isLoading: state.cart && state.cart.isFetching,
    couponCode: state.cart && state.cart.couponCode,
    couponValid: state.cart && state.cart.couponValid
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeFromCart,
      applyCoupon
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCart);
