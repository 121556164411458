// @flow
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom';
import {
  PrivateRoute,
  LogIn,
  LogOut,
  ForgotPassword,
  getAuthToken,
  getUserData
} from 'roy-morgan-auth';
import Footer from 'roy-morgan-footer';

import { idByProduct } from '../helpers';
import Header from './Header';
import Home from './Home';
import PasswordSettings from './PasswordSettings';
import ProductListing from './ProductListing';
import Product from './Product';
import Checkout from './Checkout';
import MyAccount from './MyAccount';
import MyOrder from './MyOrder';
import OrderThankYou from './OrderThankYou';
import ContactForm from './ContactForm';
import SelfRegister from './SelfRegister';
import LoadingIndicator from '../components/LoadingIndicator';
import GenericPage from './GenericPage';
import ProductMap from './ProductMap';

import type { Store } from 'redux';
import type { Dispatch } from '../types';

type Props = {
  store: Store<*, *>,
  getUserData: () => Promise<boolean>
};

type State = {
  loading: boolean
};

class Root extends React.Component<Props, State> {
  state = {
    loading: true
  };

  componentDidMount() {
    const token = getAuthToken();
    if (token && token !== '0') {
      this.props.getUserData();
    }
    this.setState({ loading: false });
  }

  render() {
    let { store } = this.props;
    if (this.state.loading) {
      return <LoadingIndicator />;
    }

    return (
      <Provider store={store}>
        <Router>
          <React.Fragment>
            <Route path="/" component={Header} />
            <div className="page-container">
              <Switch>
                {/* this route here is for creating an indexable list of products */}
                <Route exact={true} path="/productmap" component={ProductMap} />
                <Route exact={true} path="/" component={Home} />
                <Route
                  exact={true}
                  path="/login"
                  render={props => <LogIn showRegisterLink={true} {...props} />}
                />
                <Route exact={true} path="/logout" component={LogOut} />
                <PrivateRoute exact={true} path="/logout" component={LogOut} />
                <Route
                  path="/forgot-password"
                  exact={true}
                  render={props => (
                    <ForgotPassword
                      productFamilyId={idByProduct['Online Store']}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/product/:productId"
                  component={Product}
                />
                {/* This is messy but will be fine until we know how this page is structured */}
                <Route
                  exact={true}
                  path="/products"
                  component={ProductListing}
                />
                <Route
                  exact={true}
                  path="/products/:category"
                  component={ProductListing}
                />
                <Route exact={true} path="/checkout" component={Checkout} />
                <Route
                  exact={true}
                  path="/products/:category/:subcategory"
                  component={ProductListing}
                />
                <Route
                  exact={true}
                  path="/products/:category/:subcategory/:productcategory"
                  component={ProductListing}
                />
                <Route
                  exact={true}
                  path="/products/:category/:subcategory/:productcategory/:productsubcategory"
                  component={ProductListing}
                />
                <Route
                  exact={true}
                  path="/products/:category/:subcategory/:productcategory/:productsubcategory/:categoryLevel5"
                  component={ProductListing}
                />
                <Route
                  exact={true}
                  path="/products/:category/:subcategory/:productcategory/:productsubcategory/:categoryLevel5/:categoryLevel6"
                  component={ProductListing}
                />
                <Route
                  exact={true}
                  path="/thankyou"
                  component={OrderThankYou}
                />
                <Route
                  exact={true}
                  path="/my-account/details"
                  component={MyAccount}
                />
                <Route
                  exact={true}
                  path="/my-account/orders"
                  component={MyAccount}
                />
                <Route
                  exact={true}
                  path="/my-account/orders/:orderId"
                  component={MyOrder}
                />
                <Route
                  exact={true}
                  path="/terms-and-conditions"
                  component={() => (
                    <GenericPage pageSlug="terms-and-conditions" />
                  )}
                />
                <Route
                  exact={true}
                  path="/subscriptions-and-packages"
                  component={() => (
                    <GenericPage pageSlug="subscriptions-and-packages" />
                  )}
                />
                <Route
                  exact={true}
                  path="/product-descriptions"
                  component={() => (
                    <GenericPage pageSlug="product-descriptions" />
                  )}
                />
                <Route
                  exact={true}
                  path="/privacy-policy"
                  component={() => <GenericPage pageSlug="privacy-policy" />}
                />
                <Route
                  exact={true}
                  path="/refund-policy"
                  component={() => <GenericPage pageSlug="refund-policy" />}
                />
                <Route
                  exact={true}
                  path="/faq"
                  component={() => <GenericPage pageSlug="faq" />}
                />
                <Route exact={true} path="/contact" component={ContactForm} />
                <Route exact={true} path="/register" component={SelfRegister} />

                {/* Settings */}
                <PrivateRoute
                  exact={true}
                  path="/settings/password"
                  component={PasswordSettings}
                />
                <Redirect
                  exact={true}
                  from="/settings"
                  to="/my-account/details"
                />
              </Switch>
            </div>
            <Footer
              contactUsLink="/contact"
              termsAndConditionsLink="/terms-and-conditions"
              privacyPolicyLink="/privacy-policy"
              refundPolicyLink="/refund-policy"
            />
          </React.Fragment>
        </Router>
      </Provider>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getUserData
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(Root);
