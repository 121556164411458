// @flow
import { camelizeKeys } from 'humps';
import { noCamelize } from '../helpers';
import { getAuthToken, refreshToken as refresh, logOut } from 'roy-morgan-auth';

// Set BASE_URL via environment variable
export const BASE_URL =
  process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'prod'
    ? 'https://livecms.roymorgan.com'
    : process.env.REACT_APP_RM_ENV && process.env.REACT_APP_RM_ENV === 'uat'
    ? 'https://livecms.roymorganuat.com'
    : 'https://livecms.roymorgandev.com';

type props = {
  method: string,
  endpoint: string,
  token?: ?string,
  body?: Object,
  isLiveAPICall?: boolean,
  useToken?: boolean,
  selfRegisteredToken?: string
};

type APIError = Error & {
  code?: string
};

export const callAPI = async ({
  method,
  endpoint,
  body,
  isLiveAPICall,
  useToken,
  selfRegisteredToken
}: props): Promise<any> => {
  try {
    const targetUrl = isLiveAPICall ? `${endpoint}` : `${BASE_URL}${endpoint}`;
    let activeToken;
    if (isLiveAPICall === true && useToken === true) {
      activeToken = getAuthToken();
    }
    let token = selfRegisteredToken ? selfRegisteredToken : activeToken;
    let tempHeaders =
      !(typeof token === 'undefined') && token && isLiveAPICall === true
        ? new Headers({
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            token
          })
        : null;
    tempHeaders =
      typeof token === 'undefined' && isLiveAPICall === true
        ? new Headers({
            'Content-Type': 'application/json'
          })
        : tempHeaders;
    const headers = tempHeaders;
    const response =
      headers === null
        ? await fetch(targetUrl, {
            method,
            body: JSON.stringify(body)
          })
        : await fetch(targetUrl, {
            method,
            headers,
            body: JSON.stringify(body)
          });
    if (!response.ok) {
      const errorResponse = await response.json();
      const error: APIError = Error(
        errorResponse.errors ? errorResponse.errors[0].message : 'API Error'
      );
      error.code = 'API';
      throw error;
    }
    const json = await response.json();
    const camelizedJson = camelizeKeys(json, noCamelize);
    return camelizedJson;
  } catch (e) {
    console.error(e);
    if (e.code === 'API') {
      throw e;
    }
    throw new Error('API Error');
  }
};

export const refreshToken = async (dispatch: any) => {
  if (process.env.NODE_ENV !== 'test') {
    const result = await dispatch(refresh());
    if (!result) {
      dispatch(logOut());
      window.location.href = '/';
    }
  }
};
