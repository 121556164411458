// @flow
import React from 'react';
import { selfRegister } from '../actions/user/selfRegister';
import type { Dispatch } from '../types';
import { Grid, Form, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

type User = {
  name?: string,
  email?: string,
  phone?: string,
  password?: string
};

type Props = {
  user: User,
  isFetching: boolean,
  handleFormSubmit: (formData: User) => void,
  selfRegister: () => Promise<*>
};

type State = {
  formData: {
    ...User,
    confirmPassword?: string
  },
  message: ?string,
  formErrorMessage: boolean
};

class SelfRegisterFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: {
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: ''
      },
      message: null,
      formErrorMessage: false
    };
  }

  handleFormSubmitClick(e) {
    const {
      formData: { password, confirmPassword }
    } = this.state;
    if (password && confirmPassword) {
      if (password === confirmPassword) {
        this.setState({
          message: '',
          formErrorMessage: false
        });
        return true;
      } else {
        this.setState({
          message: "Password and confirm password doesn't match!",
          formErrorMessage: true
        });
        e.preventDefault();
        return false;
      }
    }
  }

  handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleCloseMessage() {
    this.setState({
      message: '',
      formErrorMessage: false
    });
    return true;
  }

  render() {
    const { isFetching } = this.props;
    const {
      name,
      email,
      phone,
      password,
      confirmPassword
    } = this.state.formData;
    const { message, formErrorMessage } = this.state;
    const { handleFormSubmit } = this.props;

    const passwordTipStyle = {
      fontSize: '11px'
    };
    return (
      <Form
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >
        <div
          className="ui error message show-message"
          style={{ display: formErrorMessage ? 'block' : 'none' }}
        >
          <i
            aria-hidden="true"
            className="close icon"
            onClick={this.handleCloseMessage.bind(this)}
          />
          <div className="content">
            <p>{message}</p>
          </div>
        </div>

        <h2>Register</h2>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Name"
                name="name"
                value={name}
                onChange={this.handleInputChange}
                type="text"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Email"
                name="email"
                value={email}
                onChange={this.handleInputChange}
                type="email"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Phone"
                name="phone"
                value={phone}
                onChange={this.handleInputChange}
                type="phone"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Password"
                name="password"
                value={password}
                onChange={this.handleInputChange}
                type="password"
                required
              />
              <p style={passwordTipStyle}>
                Passwords must be at least eight characters in length, and must
                include one uppercase letter, one lowercase letter, and one
                numeric character
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.handleInputChange}
                type="password"
                required
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Button
                className="organisation-form__submit"
                type="submit"
                loading={isFetching}
                onClick={this.handleFormSubmitClick.bind(this)}
              >
                Register
              </Button>
              <Link to="/login">Login</Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}
const mapStateToProps = (state, props) => ({
  isFetching: state.auth.user.isFetching
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selfRegister
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfRegisterFormFields);
