// @flow
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import * as React from 'react';
import { hydrate, render } from 'react-dom';
import * as Sentry from '@sentry/browser';

import Root from './containers/Root';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';

import 'roy-morgan-base-theme/dist/semantic.min.css';
// $FlowFixMe
import './assets/scss/index.scss';
import { persistCart } from './helpers/persistCart';

let savedCart = [];

if (localStorage && localStorage.getItem('royMorganStorage')) {
  const storage = JSON.parse(localStorage.getItem('royMorganStorage') || '{}');

  if (storage && storage['loggedout']) {
    savedCart = storage['loggedout'];
  }
}

const store = configureStore({
  cart: {
    isFetching: false,
    cartItems: savedCart
  }
});

// Get RM environment to pass through to Sentry
const env = process.env.REACT_APP_RM_ENV || '';

// Don't run in local development.
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://98869e631dec49749e8248a5547ae606@sentry.io/1434365',
    environment: env
  });
}

// handle shopping cart persistence
store.subscribe(() => persistCart(store));

const rootElement = document.getElementById('root');

if (rootElement && rootElement.hasChildNodes()) {
  hydrate(<Root store={store} />, rootElement);
} else if (rootElement) {
  render(<Root store={store} />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
