// @flow
import * as React from 'react';
import {
  Grid,
  Form,
  Button,
  Dropdown,
  Container,
  GridRow
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import PageBanner from '../components/PageBanner';
import { bindActionCreators } from 'redux';
import { contactFormSubmit } from '../actions/pages/contact';
import type { Dispatch } from '../types';

type Contact = {
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string,
  country?: string,
  company?: string,
  message?: string
};

type Props = {
  contact: Contact,
  successMessage: string,
  successLink: {},
  reportSuccessMessage: string,
  submitted: boolean,
  onSubmit: (v: Object) => void,
  contactFormSubmit: (formData: Contact) => Promise<null>
};

type State = {
  formData: Contact,
  isFetching: boolean,
  countries: Object,
  formMessage: ?string,
  msgClass: ?string,
  showMsg: ?boolean
};

class ContactForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const countryList = require('country-list');
    const countries = countryList.getData();

    // $FlowFixMe
    this.handleInputChange = this.handleInputChange.bind(this);
    // $FlowFixMe
    this.handleMobileInputChange = this.handleMobileInputChange.bind(this);
    // $FlowFixMe
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        country: 'Australia'
      },
      isFetching: false,
      countries: countries,
      formMessage: '',
      msgClass: 'invalid',
      showMsg: false
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  }

  handleMobileInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (event.target.value.match('^[+0-9 ]*$') !== null) {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value
        }
      });
    }
  }

  handleCountryChange(event, data) {
    const value = data.value;
    const name = 'country';

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value
      }
    });
  }

  async submitForm(ev) {
    ev.preventDefault();
    const { formData } = this.state;

    this.setState({
      isFetching: true
    });

    const {
      firstName,
      lastName,
      phone,
      company,
      email,
      country,
      message
    } = formData;

    const payload = {
      firstName,
      lastName,
      phone,
      company,
      email,
      country,
      message
    };

    let response = this.props.contactFormSubmit(payload);
    if (response) {
      this.setState({
        isFetching: false,
        formMessage: 'Contact form submitted successfully.',
        showMsg: true,
        msgClass: 'valid',
        formData: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          company: '',
          message: '',
          country: 'Australia'
        }
      });
    } else {
      this.setState({
        isFetching: false,
        showMsg: true,
        formMessage: 'There is some issue with submitting Contact form.',
        msgClass: 'invalid'
      });
    }
  }

  render() {
    const { formData, isFetching, formMessage, msgClass, showMsg } = this.state;

    const {
      firstName,
      lastName,
      company,
      email,
      phone,
      message,
      country
    } = formData;

    const countryList = require('country-list');
    const countries = countryList.getData();
    const formattedCountries = countries.map(country => ({
      key: country.code,
      value: country.name,
      text: country.name
    }));

    // By default selected country is Australia
    const selectedCountry = country ? country : 'Australia';

    return (
      <div>
        <PageBanner
          title="Contact Us"
          backgroundUrl="https://s3-ap-southeast-2.amazonaws.com/rmr-uat-live-cms-uploads/wp-content/uploads/2018/11/18170333/checkout-banner.jpg"
        />
        <Container className="contact-page">
          <Grid>
            <GridRow>
              <Grid.Column width={12}>
                <Form
                  onSubmit={this.submitForm.bind(this)}
                  className="contact-form__form"
                >
                  <Grid centered stackable>
                    <Grid.Column tablet={6} computer={6}>
                      <Form.Input
                        name="firstName"
                        onChange={this.handleInputChange}
                        value={firstName}
                        label="First Name"
                        required
                        maxLength="20"
                      />
                    </Grid.Column>
                    <Grid.Column tablet={6} computer={6}>
                      <Form.Input
                        name="lastName"
                        onChange={this.handleInputChange}
                        value={lastName}
                        label="Last Name"
                        required
                        maxLength="20"
                      />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Form.Input
                        name="email"
                        onChange={this.handleInputChange}
                        label="Email"
                        required
                        value={email}
                        type="email"
                        maxLength="60"
                      />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Form.Input
                        name="phone"
                        label="Phone"
                        type="phone"
                        value={phone}
                        required
                        maxLength="15"
                        onChange={this.handleMobileInputChange}
                      />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      {/* eslint-disable */}
                      <label>
                        <strong>Country</strong>
                      </label>
                      {/* eslint-enable */}
                      <Dropdown
                        placeholder="Select Country..."
                        fluid
                        selection
                        search
                        value={selectedCountry}
                        name="country"
                        options={formattedCountries}
                        onChange={this.handleCountryChange.bind(this)}
                      />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <Form.Input
                        name="company"
                        onChange={this.handleInputChange}
                        label="Company"
                        required
                        value={company}
                        maxLength="45"
                      />
                    </Grid.Column>
                    <Grid.Column width={12}>
                      <Form.TextArea
                        name="message"
                        onChange={this.handleInputChange}
                        as={Form.TextArea}
                        label="Message"
                        value={message}
                        maxLength={1500}
                      />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                      <Button
                        className="contact-form__submit am_contact_form"
                        loading={isFetching}
                        disable={isFetching}
                      >
                        Send Message
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Grid.Column>
              <Grid.Column width={12} className="contact-page__block">
                {showMsg && <span className={msgClass}>{formMessage}</span>}
              </Grid.Column>
            </GridRow>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      contactFormSubmit
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm);
