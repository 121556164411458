//@flow
import React from 'react';

function PhoneLink() {
  return (
    <a href="tel:1800 633 813" className="am_phone_click">
      1800 633 813
    </a>
  );
}

function EmailLink() {
  return (
    <a href="mailto:enquiries@roymorgan.com" className="am_email_click">
      enquiries@roymorgan.com
    </a>
  );
}

export default { PhoneLink, EmailLink };
