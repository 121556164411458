//@flow
import React from 'react';
import { Container } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PageBanner from '../components/PageBanner';
import RoyMorganContact from '../components/RoyMorganContact';
import { Link } from 'react-router-dom';
import type { Dispatch } from '../types';
import { getOrder } from '../actions/user/order';

type Props = {
  order: string,
  paymentmethod: any,
  getOrder: (id: number | string) => Promise<any>
};

class OrderThankYou extends React.Component<Props> {
  getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  componentDidMount() {
    if (this.props.order) {
      window.dataLayer = window.dataLayer || [];
      this.props.getOrder(this.props.order).then(function(order) {
        let products = [];
        order.items.forEach(item => {
          products.push({
            id: item.id,
            name: item.name,
            price: item.total,
            quantity: 1,
            discount: item.discount
          });
        });
        window.dataLayer.push({
          event: 'eec.purchase',
          ecommerce: {
            currencyCode: 'AUD',
            purchase: {
              actionField: {
                id: order.id,
                revenue: order.total,
                tax: order.gst,
                discount: order.discount
              },
              products: products
            }
          }
        });
      });
    }
  }

  render() {
    const { order, paymentmethod } = this.props;
    return (
      <div>
        <PageBanner
          title="Thank you"
          backgroundUrl="https://s3-ap-southeast-2.amazonaws.com/rmr-uat-live-cms-uploads/wp-content/uploads/2018/11/18170333/checkout-banner.jpg"
        />

        <Container className="thank-you-content">
          <h2>Thank You for your order</h2>

          {order && (
            <p>
              Your order number is #<b>{order}</b>
            </p>
          )}

          {paymentmethod === 2 && (
            <div>
              <p>
                To complete your transaction please follow the instruction
                below. We will also email you a copy of the payment instruction
                shortly.
              </p>
              <p>
                <strong>EFT Payment Instruction:</strong>
                <br />
                ANZ Banking Group Limited
                <br />
                388 Collins Street,
                <br />
                Melbourne VIC 3000
                <br />
                <br />
                <strong>Account Name:</strong>
                <br />
                Roy Morgan Research Ltd
                <br />
                <strong>Account Number:</strong>
                <br />
                013 - 006 837067426
                <br />
                <br />
              </p>
            </div>
          )}

          {order && (
            <p>
              You can view your order details in your{' '}
              <Link to={'/my-account/orders/' + order}>My Account area</Link>
            </p>
          )}

          {paymentmethod === 1 && (
            <p>
              All existing reports are available for immediate download and you
              will receive an email shortly regarding any custom or new reports
            </p>
          )}

          <p>
            For any issues please email <RoyMorganContact.EmailLink /> or
            call&nbsp;
            <RoyMorganContact.PhoneLink />
          </p>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    order: state.checkout.thanyouOrder,
    paymentmethod: state.checkout.paymentmethod
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrder
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderThankYou);
