// @flow
import React from 'react';
import ProductIcons from './ProductIcons';
import PriceAndControls from './PriceAndControls';

type Props = {
  product: Object,
  addToCart: (e: any, product: Object) => void,
  itemExistsInCart: boolean
};

function HeaderItems({ product, addToCart, itemExistsInCart }: Props) {
  return (
    <div className="header-items">
      <ProductIcons product={product} />
      <PriceAndControls
        product={product}
        addToCart={addToCart}
        itemExistsInCart={itemExistsInCart}
      />
    </div>
  );
}

export default HeaderItems;
