// @flow
import * as React from 'react';
import { Icon, Button } from 'semantic-ui-react';

type Props = {
  product: Object
};

class ProductIcons extends React.PureComponent<Props> {
  render() {
    const { product } = this.props;

    return (
      <div className="product-icons">
        <div className="product-icons__icon-wrapper">
          <Button
            className="product-icons__icon product-icons__icon--questions"
            onClick={() =>
              window.open(
                `mailto:askroymorgan@roymorgan.com?subject=Online Store Enquiry - ${
                  product.name
                }`,
                '_blank'
              )
            }
          >
            <div className="text">i</div>
          </Button>
          <p className="product-icons__text">
            <a
              href={
                'mailto:askroymorgan@roymorgan.com?subject=Online Store Enquiry - ' +
                product.name
              }
            >
              Question? Ask us about the product
            </a>
          </p>
        </div>
        {product.sampleRef && (
          <div className="product-icons__icon-wrapper">
            <Button
              className="product-icons__icon product-icons__icon--sample"
              onClick={() => window.open(product.sampleRef, '_blank')}
            >
              <Icon className="eye icon" />
            </Button>
            <p className="product-icons__text">
              <a
                href={product.sampleRef}
                target="_blank"
                rel="noopener noreferrer"
              >
                View a sample preview of the report
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default ProductIcons;
