// @flow
import * as React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Elements, StripeProvider } from 'react-stripe-elements';

import FlashMessage from './FlashMessage';
import CheckoutFormAndDetails from './CheckoutFormAndDetails';
import PageBanner from '../components/PageBanner';
import { processOrder, processOrderClearLast } from '../actions/cart/checkout';
import { newsletterSignup } from '../actions/pages/campaignMonitor';
import type { RouterHistory } from 'react-router-dom';
import type { CheckoutState, Dispatch } from '../types';

type Props = {
  history: RouterHistory,
  checkout: CheckoutState,
  processOrder: Function,
  processOrderClearLast: Function,
  isFetching: boolean,
  user: Object,
  company: Object,
  newsletterSignup: Function
};

class Checkout extends React.Component<Props> {
  submitCharge = (payload, selfRegisteredToken) => {
    this.props.processOrder(payload, selfRegisteredToken);
    if (payload.signup_to_newsletter) {
      try {
        this.props.newsletterSignup(payload.name, payload.email);
      } catch (err) {
        Sentry.captureException(err);
        console.error('Error signing up to newsletter'); //don't fail payment due to newsletter
      }
    }
  };

  clearLastGoToThankYou = () => {
    const { history, processOrderClearLast } = this.props;
    processOrderClearLast();
    if (history) {
      history.push('/thankyou');
    }
  };

  render() {
    const { history, checkout, isFetching, user, company } = this.props;
    if (checkout && checkout.lastOrder && checkout.lastOrder.id) {
      this.clearLastGoToThankYou();
    }
    var stripeToken = process.env.REACT_APP_STRIPE_PUBLIC_KEY
      ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
      : 'pk_test_nmHPhU0F01ZjWt4bMEz9paoe';
    return (
      <div>
        <PageBanner
          title="Checkout"
          backgroundUrl="https://s3-ap-southeast-2.amazonaws.com/rmr-uat-live-cms-uploads/wp-content/uploads/2018/11/18170333/checkout-banner.jpg"
        />
        <Container>
          <Grid>
            <Grid.Column width={12}>
              <div className="single-form">
                <FlashMessage />
                <StripeProvider apiKey={stripeToken}>
                  <Elements>
                    <CheckoutFormAndDetails
                      handleFormSubmit={this.submitCharge}
                      history={history}
                      isFetching={isFetching}
                      user={user}
                      company={company}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    user: state.auth.user.user,
    company:
      state.auth.organisations.byId[
        state.auth.organisations.currentOrganisation
      ],
    checkout: state.checkout,
    isFetching: state.checkout.isFetching
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      processOrder,
      processOrderClearLast,
      newsletterSignup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
