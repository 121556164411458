import { combineReducers } from 'redux';
import { reducer as authReducer } from 'roy-morgan-auth';
import { messages } from './messages';
import { homepageContent } from './homepage';
import { homepageSearch } from './homepageSearch';
import { cmsProductsOptions } from './cmsProductsOptions';
import { allProductList, productList, treenav, productPage } from './products';
import { cart } from './cart';
import { checkout } from './checkout';
import { orders } from './orders';
import { order } from './order';
import { pages } from './pages';
import { lastAction } from './lastAction';

const rootReducer = combineReducers({
  auth: authReducer,
  messages,
  homepageContent,
  homepageSearch,
  cmsProductsOptions,
  allProductList,
  productList,
  productPage,
  treenav,
  cart,
  checkout,
  order,
  orders,
  pages,
  lastAction
});

export default rootReducer;
